import { default as aboutcGvMViY0SnMeta } from "/opt/buildhome/repo/pages/about.vue?macro=true";
import { default as contactupLWW5VsuQMeta } from "/opt/buildhome/repo/pages/contact.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as Arterial1NEAH1AAACmzMuMeta } from "/opt/buildhome/repo/pages/planning/Arterial1NEA.vue?macro=true";
import { default as _91project_93i3sdgFUsMmMeta } from "/opt/buildhome/repo/pages/projects/[project].vue?macro=true";
import { default as indexYXkKyiAwK3Meta } from "/opt/buildhome/repo/pages/projects/index.vue?macro=true";
import { default as indexVrpVjnZSa3Meta } from "/opt/buildhome/repo/pages/services/index.vue?macro=true";
import { default as _91member_93n9UZJzDFseMeta } from "/opt/buildhome/repo/pages/team/[member].vue?macro=true";
import { default as careers11GtRmor5lMeta } from "/opt/buildhome/repo/pages/team/careers.vue?macro=true";
import { default as index4g5Fu3kzfYMeta } from "/opt/buildhome/repo/pages/team/index.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    meta: aboutcGvMViY0SnMeta || {},
    component: () => import("/opt/buildhome/repo/pages/about.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactupLWW5VsuQMeta || {},
    component: () => import("/opt/buildhome/repo/pages/contact.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexniDiYCWjuTMeta || {},
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "planning-Arterial1NEA",
    path: "/planning/Arterial1NEA",
    meta: Arterial1NEAH1AAACmzMuMeta || {},
    component: () => import("/opt/buildhome/repo/pages/planning/Arterial1NEA.vue")
  },
  {
    name: "projects-project",
    path: "/projects/:project()",
    meta: _91project_93i3sdgFUsMmMeta || {},
    component: () => import("/opt/buildhome/repo/pages/projects/[project].vue")
  },
  {
    name: "projects",
    path: "/projects",
    meta: indexYXkKyiAwK3Meta || {},
    component: () => import("/opt/buildhome/repo/pages/projects/index.vue")
  },
  {
    name: "services",
    path: "/services",
    meta: indexVrpVjnZSa3Meta || {},
    component: () => import("/opt/buildhome/repo/pages/services/index.vue")
  },
  {
    name: "team-member",
    path: "/team/:member()",
    meta: _91member_93n9UZJzDFseMeta || {},
    component: () => import("/opt/buildhome/repo/pages/team/[member].vue")
  },
  {
    name: "team-careers",
    path: "/team/careers",
    meta: careers11GtRmor5lMeta || {},
    component: () => import("/opt/buildhome/repo/pages/team/careers.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: index4g5Fu3kzfYMeta || {},
    component: () => import("/opt/buildhome/repo/pages/team/index.vue")
  }
]